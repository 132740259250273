/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.payment-methods {
  display: flex;
  width: 100%;
}
.payment-methods__option {
  box-sizing: border-box;
  width: 30%;
  max-width: 220px;
  border-radius: 6px;
  background: #22222a;
  border: 2px solid #646472;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 24px;
}
.payment-methods__option label {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.payment-methods__option__check {
  position: absolute;
  top: 10px;
  left: 9px;
}
.payment-methods__option__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80px;
  padding: 14px 0;
}
.payment-methods__option__content__icon {
  padding-top: 12px;
}
.payment-methods__option__content__icon svg {
  max-height: 24px;
  height: 24px;
  width: 100%;
  max-width: 90px;
}
.payment-methods__option__content__icon--selected {
  color: #0d0d10;
}
.payment-methods__option__content__name {
  color: #eee;
  padding-top: 6px;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}
.payment-methods__option__content__name--selected {
  color: #0d0d10;
}
.payment-methods__option:hover, .payment-methods__option--selected {
  border-color: #fefefe;
}
.payment-methods__option--selected {
  background: #fefefe;
  color: #0d0d10;
}