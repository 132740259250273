/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.cardinfo__arrows__button {
  padding: 24px 24px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  display: none;
}
@media (min-width: 41.87em) {
  .cardinfo__arrows__button {
    display: block;
  }
}
.cardinfo__arrows__button svg {
  font-size: 3em;
}
.cardinfo__arrows__button--disabled {
  opacity: 0;
  cursor: default;
}
.cardinfo__carousel__wrapper {
  position: relative;
  flex-grow: 0;
}
@media (min-width: 41.87em) {
  .cardinfo__carousel__wrapper {
    flex-grow: 1;
  }
}
.cardinfo__content {
  display: flex;
  flex-direction: column;
  max-width: 420px;
}
@media (min-width: 41.87em) {
  .cardinfo__content {
    padding: 0;
    max-width: 100%;
  }
}
@media (min-width: 64em) {
  .cardinfo__content {
    width: 790px;
  }
}
.cardinfo__main {
  display: flex;
  flex-direction: column;
}
@media (min-width: 41.87em) {
  .cardinfo__main {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.cardinfo__cardarea {
  text-align: center;
  flex-shrink: 0;
}
@media (min-width: 41.87em) {
  .cardinfo__cardarea {
    max-width: 288px;
    padding-right: 48px;
    width: 100%;
    align-self: normal;
    display: flex;
    flex-direction: column;
  }
}
.cardinfo__image-wrapper {
  flex: 2;
}
.cardinfo__image {
  min-width: 50px;
  max-height: 250px;
  margin-bottom: 24px;
  user-select: none;
}
@media (min-width: 41.87em) {
  .cardinfo__image {
    max-width: 240px;
    max-height: inherit;
  }
}
.cardinfo__disenchant {
  margin-bottom: 24px;
}
.cardinfo__disenchant svg {
  font-size: 1.5em;
  margin-left: 0.2em;
  margin-right: 0.2em;
}
.cardinfo__infoarea {
  width: 100%;
  min-width: 320px;
  padding: 0 24px;
}
@media (min-width: 400px) {
  .cardinfo__infoarea {
    min-width: 370px;
  }
}
@media (min-width: 41.87em) {
  .cardinfo__infoarea {
    min-width: 280px;
    padding: 0;
  }
}
@media (min-width: 800px) {
  .cardinfo__infoarea {
    min-width: 370px;
  }
}
.cardinfo__title {
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 12px;
}
.cardinfo__action {
  color: #646473;
  margin-bottom: 48px;
}
.cardinfo__status {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.cardinfo__status--right {
  justify-content: flex-end;
  margin-top: 12px;
}
.cardinfo__status--right .cardinfo__status__icon {
  margin-right: 0;
  margin-left: 12px;
}
.cardinfo__status__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 3px;
  height: 24px;
  width: 24px;
  background-color: #0d0d10;
  border-radius: 3px;
  margin-right: 12px;
}
.cardinfo__status__icon svg {
  font-size: 20px;
}
.cardinfo__status__icon--notinchests {
  background-color: #cc0022;
}
.cardinfo__status__icon--cooldown {
  background-color: #137ffb;
}
.cardinfo__status__icon--cooldown--negative {
  background: #cc0022;
}
.cardinfo__status__icon--fragmented {
  background-color: #137ffb;
}
.cardinfo__status__icon--paused {
  background: #cc0022;
}
.cardinfo__status__icon--limited {
  background-color: #137ffb;
}
.cardinfo__status__icon--redeemable {
  background-color: #137ffb;
}
.cardinfo__status__icon--craftable {
  background-color: #137ffb;
}
.cardinfo__status__icon--nocards {
  background-color: #e64759;
}
.cardinfo__status__icon--paid {
  background-color: #137ffb;
}
.cardinfo__actions {
  width: 100%;
  text-align: center;
  margin-bottom: 72px;
}
@media (min-width: 480px) {
  .cardinfo__actions {
    text-align: right;
    margin-bottom: 0;
  }
}
.cardinfo__button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.cardinfo__button-wrapper button {
  width: 100%;
}
@media (min-width: 480px) {
  .cardinfo__button-wrapper button {
    width: unset;
  }
}
.cardinfo__effect-selector {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  padding-bottom: 12px;
}
.cardinfo__effect-selector .rarity-effect {
  margin-top: 3px;
  max-width: 300px;
  transform: translateX(calc(50% - 57px));
}
@media (max-width: 669px) {
  .cardinfo__effect-selector {
    position: static;
    margin-top: 14px;
    align-items: center;
  }
  .cardinfo__effect-selector button {
    position: relative;
    top: unset;
    margin: 0 auto;
  }
  .cardinfo__effect-selector .rarity-effect {
    margin-bottom: 8px;
    transform: unset;
  }
  .cardinfo__effect-selector .new {
    top: 21px;
    right: unset;
    left: calc(50% - 122px);
  }
}

.mobile-cta {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 650;
  background: linear-gradient(180deg, #0b0b0e 0%, #0c0c0f 28.65%);
  padding: 18px 24px;
}
.mobile-cta > button {
  width: 100%;
}

.card-extra-info {
  margin-bottom: 48px;
}
.card-extra-info-opener {
  display: none;
  margin-top: 24px;
  margin-bottom: 12px;
  color: #60ffcc !important;
}
.card-extra-info-opener svg {
  transition: all 0.3s;
}
.card-extra-info-opener--open svg {
  transform: rotate(180deg);
}
@media (max-width: 669px) {
  .card-extra-info {
    transition: all 0.3s;
    overflow: hidden;
    max-height: 0;
    margin-bottom: 24px;
  }
  .card-extra-info-opener {
    display: flex;
  }
  .card-extra-info--open {
    max-height: 580px;
  }
}