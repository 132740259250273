/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.payment-step-header__title {
  font-weight: normal;
  font-size: 0.875rem;
  color: #eee;
}
.payment-step-header__revenue {
  font-size: 0.875rem;
  font-weight: 600;
  color: #5defc1;
}

.subscription-info {
  text-align: left;
  font-size: 0.875rem;
  color: #eee;
  margin-bottom: 24px;
}
.subscription-info__title {
  font-weight: 600;
  margin-bottom: 6px;
}

.payment-summary {
  margin-top: 24px;
  margin-bottom: 48px;
}
.payment-summary__line {
  display: flex;
  justify-content: space-between;
}
.payment-summary__line--discount > span:last-child {
  color: #00ff80;
}
.payment-summary__line--total {
  font-weight: 600;
  padding-top: 6px;
  border-top: 2px solid #fff;
}

.subscription-perks {
  gap: 36px;
  display: flex;
  flex-wrap: wrap;
  max-width: 940px;
  justify-content: center;
}

.subscription-perk {
  width: 100%;
  max-width: 180px;
  text-align: center;
}
@media (min-width: 500px) {
  .subscription-perk {
    min-width: 150px;
  }
}
@media (min-width: 650px) {
  .subscription-perk {
    max-width: 251px;
  }
}
.subscription-perk__icon {
  font-size: 50px;
}
.subscription-perk__media {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-perk__username {
  font-size: 40px;
  text-shadow: 0px 2px 10px red, 0 0 5px red, 0 0 10px orange, 0 0 7px yellow, 0 0 5px yellow, -1px -1px 2px yellow;
}
.subscription-perk img {
  max-height: 60px;
  border-radius: 6px;
}